import { OpenK9 } from "@openk9/search-frontend";

export type SearchToken =
  | {
      tokenType: "DATASOURCE";
      keywordKey?: undefined;
      values: string[];
      filter: boolean;
      suggestionCategoryId?: number;
      count?: string;
    }
  | {
      tokenType: "DOCTYPE";
      keywordKey: "type";
      values: string[];
      filter: boolean;
      suggestionCategoryId?: number;
      count?: string;
    }
  | {
      tokenType: "TEXT";
      keywordKey?: string;
      values: string[];
      filter: boolean;
      goToSuggestion?: boolean;
      label?: string;
      suggestionCategoryId?: number;
      count?: string;
    }
  | {
      tokenType: "ENTITY";
      keywordKey?: string;
      entityType: string;
      entityName: string;
      values: string[];
      filter: boolean;
      suggestionCategoryId?: number;
      count?: string;
    }
  | {
      tokenType: "DATE";
      keywordKey?: string;
      extra: {
        gte: number;
        lte: number;
      };
      suggestionCategoryId?: number;
      count?: string;
    };

export type SuggestionResult =
  | {
      tokenType: "DATASOURCE";
      suggestionCategoryId: number;
      value: string;
      keywordKey?: string;
      count?: string;
    }
  | {
      tokenType: "TEXT";
      suggestionCategoryId: number;
      keywordKey?: string;
      value: string;
      count?: string;
    }
  | {
      tokenType: "ENTITY";
      suggestionCategoryId: number;
      entityType: string;
      entityValue: string;
      keywordKey?: string;
      value: string;
      count?: string;
    }
  | {
      tokenType: "DOCTYPE";
      suggestionCategoryId: number;
      value: string;
      keywordKey?: string;
      count?: string;
    };
let openk9: OpenK9 | undefined;

const timeoutTime = 5000;
const retry = 100;

function waitForTenantUrl(timeout = timeoutTime): Promise<string> {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (window.openk9_tenant_url) {
        clearInterval(interval);
        resolve(window.openk9_tenant_url);
      }
    }, retry);

    setTimeout(() => {
      clearInterval(interval);
      reject(
        new Error("Timeout: window.openk9_tenant_url non è stata valorizzata.")
      );
    }, timeout);
  });
}

async function initializeOpenK9() {
  try {
    openk9 = new OpenK9({
      enabled: true,
      tenant: "https://ricerca-coll.comune.milano.it",
      searchAutoselect: false,
      showSyntax: false,
      useQueryAnalysis: false,
      viewButton: true,
      useKeycloak: false,
      defaultString: new URLSearchParams(window.location.search).get(
        "keywords"
      ),
    });
  } catch (error) {
    console.error(error);
  }
}

initializeOpenK9();

export { openk9 };
