import React from "react";

export function ResultSvg({ size = 26 }) {
  return (
    <svg
      className="openk9-result-icon title-icon"
      width="28"
      height="16"
      viewBox="0 0 28 18"
      aria-hidden="true"
      fill="none"
    >
      <path
        d="M7.33329 4H0.666626V1.33333H7.33329V4ZM7.33329 8H0.666626V10.6667H7.33329V8ZM25.4533 17.3333L20.3466 12.2267C19.28 12.92 18.0266 13.3333 16.6666 13.3333C12.9866 13.3333 9.99996 10.3467 9.99996 6.66667C9.99996 2.98667 12.9866 0 16.6666 0C20.3466 0 23.3333 2.98667 23.3333 6.66667C23.3333 8.02667 22.92 9.28 22.2266 10.3333L27.3333 15.4533L25.4533 17.3333ZM20.6666 6.66667C20.6666 4.46667 18.8666 2.66667 16.6666 2.66667C14.4666 2.66667 12.6666 4.46667 12.6666 6.66667C12.6666 8.86667 14.4666 10.6667 16.6666 10.6667C18.8666 10.6667 20.6666 8.86667 20.6666 6.66667ZM0.666626 17.3333H14V14.6667H0.666626V17.3333Z"
        className="openk9-logo-result-color"
        fill="#C0272B"
      />
    </svg>
  );
}
