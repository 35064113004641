import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

declare global {
  interface Window {
    openk9_tenant_url: string;
  }
}

window.onload = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('comune-milano-openk9-container') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <main id="main-content">
        <h1 className="visually-hidden">Pagina di ricerca</h1>
        <App />
        </main>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
